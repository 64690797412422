/* eslint-disable prettier/prettier */
import _ from 'lodash';
import BLayout from './BLayout';
const BASE_URL = 'https://minio.dcomcy.com/dev';

const createLayout = (layout, page, book) => {
  let props = {
    _id: layout._id,
    width: parseInt(book.sizeWidthPixel),
    height: parseInt(book.sizeHeightPixel),
    background: layout.background,
    backgroundSrc: layout.backgroundSrc,
    objects: [...layout.objects],
  };
  props.objects = _.sortBy(props.objects, 'ordinal');
  for (let i in props.objects) {
    props.objects[i].ordinal = parseInt(i) + 1;
  }
  return new BLayout(props);
};

const toPageLayout = (pageLayout, blayout) => {
  let objects = [];
  for (let obj of blayout.objects) {
    let object = { ...obj };
    object.style = object.style ? object.style.toString() : undefined;
    if (object.src) object.src = undefined;
    object.visible = undefined;
    objects.push(object);
  }
  return {
    ...pageLayout,
    objects: objects,
  };
};

export default {
  createLayout,
  toPageLayout,
};
