<template>
  <div class="be-main"></div>
</template>

<script>
export default {
  data() {
    return {
      plugin: null,
    };
  },
  computed: {
    blayout() {
      return this.$store.state.bookeditor.blayout;
    },
    style() {
      return this.blayout?.style || {};
    },
    objects() {
      return this.blayout?.objects || [];
    },
    mode() {
      return this.$store.state.bookeditor.mode;
    },
    selected() {
      return this.$store.state.bookeditor.selected;
    },
    slot() {
      return this.$store.state.bookeditor.slot;
    },
  },
  watch: {
    blayout() {
      this.loadLayout();
    },
    mode() {
      if (!this.plugin) return;
      if (this.mode === 'select') {
        this.plugin.pageditor('reset');
      }
    },
    selected() {
      if (!this.plugin) return;
      this.plugin.pageditor('select', this.selected);
    },
    slot() {
      if (!this.slot || !this.plugin) return;
      this.plugin.pageditor('presert', this.slot);
      this.$store.commit('bookeditor/presert', null);
    },
    objects() {
      if (!this.plugin) return;
      this.plugin.pageditor('refresh');
    },
  },
  methods: {
    loadLayout() {
      if (!this.plugin) return;
      this.plugin.pageditor('load', this.blayout);
      this.plugin.pageditor('reset');
    },
  },
  mounted() {
    this.plugin = $(this.$el).pageditor({
      class: 'be-page',
      baseUrl: 'https://minio.dcomcy.com/hiweb-development',
    });
    this.plugin.on('pageditor.selection', (e, data) => {
      this.$store.commit('bookeditor/select', data.selected);
    });
    this.plugin.on('pageditor.mode', (e, data) => {
      this.$store.commit('bookeditor/setMode', data.mode);
    });
    this.plugin.on('pageditor.insert', (e, data) => {
      //insert
    });
    this.plugin.on('pageditor.transform', (e, data) => {
      //transform
    });
    this.loadLayout();
  },
};
</script>
